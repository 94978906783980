<template>
  <v-hover v-slot:default="{ hover }" open-delay="200">
    <v-card :elevation="hover ? 16 : 2" max-width="480" max-height="480">
      <v-card
        flat
        fluid
        @click="select ? $emit('select', test) : $emit('preview', test)"
      >
        <!-- {{test.creatorType}}  {{test.entity}} -->
        <v-overlay v-if="selectedTests" absolute :value="selected(test)">
          <v-icon class="primary--text" x-large>mdi-check</v-icon>
        </v-overlay>
        <div style="height: 150px">
          <v-img
            lazy-src="https://picsum.photos/id/11/10/6"
            :src="
              test.image ? `${apiUrl}/images/test/${test.image}` : `/teste.jpeg`
            "
            height="150px"
          >
            <v-system-bar window flat color="rgba(0, 0, 0, 0)" dense>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
                    <v-icon color="primary">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item
                    @click.prevent="$emit('edit', Object.assign({}, test))"
                  >
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="reject()" >
                    <v-list-item-title>Rejeitar</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$emit('delete', test.id)">
                    <v-list-item-title>Remover</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <strong
                v-if="
                  test.entity && test.creatorType != 'entity' && test.status
                "
                class="success--text"
                >Aprovado</strong
              >
              <strong
                v-else-if="
                  test.entity &&
                  test.creatorType != 'entity' &&
                  !test.status &&
                  test.reason === null
                "
                class="success--text"
                >Personalisado</strong
              >
              <strong
                v-else-if="
                  test.entity &&
                  test.creatorType === 'partner' &&
                  !test.status &&
                  test.reason != null
                "
                class="error--text"
                >{{ $t("partner_reject_test") }}</strong
              >
              <strong
                v-else-if="
                 !(test.entity) &&
                  test.creatorType === 'partner' &&
                  !test.status &&
                  test.reason != null
                "
                class="error--text"
                >{{ $t("partner_reject_test") }}</strong
              >
              <v-spacer></v-spacer>

              <v-chip dark small label class="text-capitalize">
                {{ $t(test.type) }}
              </v-chip>
            </v-system-bar>
          </v-img>
        </div>
<!-- <pre>
  {{test}}
</pre> -->
        <v-card-text>
          <div
            v-if="test.title.length >= 36"
            class="text-subtitle-2 black--text"
          >
            {{ test.title }}
          </div>
          <div v-else class="text-subtitle-2 black--text">
            {{ test.title }} <br /><br />
          </div>
          <div class="text-capitalize py-3 px-0" style="font-size: 12px">
            Teste elaborado por {{ getUserName(test.user.name) }}
          </div>
          <div
            v-if="test.description.length > 90"
            class="black--text text-justify py-1"
            style="font-size: 14px; height: 50px"
          >
            {{
              test.description ? test.description.substring(0, 60) + "..." : ""
            }}
          </div>
          <div
            v-else
            class="black--text text-justify"
            style="font-size: 14px; height: 50px"
          >
            {{ test.description ? test.description : "" }}
          </div>
        </v-card-text>
      </v-card>
      <v-card-actions>
        <v-row class="d-flex flex-row justify-end align-center pa-5">
          <v-btn small color="primary">
            <v-icon small>mdi-cart</v-icon>
            {{
              test.price || test.price > 0
                ? formatCurrency(test.price)
                : "Gratuito"
            }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="select"
            color="purple"
            text
            small
            @click="$emit('preview', test)"
          >
            Visualizar
          </v-btn>
          <v-btn v-else icon small>
            <v-icon small color="black">mdi-share-variant</v-icon>
          </v-btn>
        </v-row>
      </v-card-actions>

      <error-dialog
        :dialog="showError"
        :message="error"
        @close="closeErrorDialog"
      />
      <v-dialog v-model="dialog" persistent max-width="500px">
        <v-card>
          <v-card-title> Motivo da rejeição </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-textarea
                v-model="reason"
                v-if="!test.reason"
                counter
                label="Descreva o reason da rejeição"
                :rules="rules"
              ></v-textarea>
              <v-textarea
              v-else
                v-model="test.rejection"
                counter
                :disabled="true"
                label="Descreva o reason da rejeição"
                :rules="rules"
              ></v-textarea>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row
              class="d-flex flex-row justify-end align-center mt-8 mb-1 mx-3"
            >
              <v-btn color="primary" text @click="reject"> Fechar </v-btn>
              <v-btn color="primary" @click="validate" :disabled="!valid" v-if="!test.reason">
                Rejeitar
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <progress-dialog :processing="isLoading" />
      <success-dialog
        :dialog="showSuccess"
        :message="success"
        @close="showSuccess = false"
      />
    </v-card>
  </v-hover>
</template>

<script>
import { API_URL } from "@/api";
import { mapActions, mapGetters } from "vuex";
import formatCurrencyMixins from "@/mixins/format-currency";
import { UPDATE_TEST_STATUS_MUTATION } from "../graphql/Mutation.gql";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "../../../components/SuccessDialog.vue";
import { formatError } from "@/utils";
export default {
  name: "TestCard",
  props: {
    selectedTests: Array,
    test: Object,
    select: Boolean,
  },
  components: {
    ErrorDialog,
    ProgressDialog,
    SuccessDialog,
  },
  mixins: [formatCurrencyMixins],
  data: () => ({
    apiUrl: API_URL,
    showSuccess: false,
    isLoading: false,
    showError: false,
    success: "",
    error: "",
    dialog: false,
    valid: true,
    rules: [(v) => !!v || "Campo obrigatório"],
    reason: "",
  }),
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  methods: {
    ...mapActions({
      setTestState: "test/setTest",
      updateTestState: "test/updateTest",
    }),
    reject() {
      this.dialog = !this.dialog;
      // this.test.reason = null;
    },
    getUserName(name) {
      let names = name.split(" ");
      return names.length > 1 ? names[0] : name;
    },
    selected(test) {
      const index = this.selectedTests.findIndex((t) => t.id == test.id);
      return index > -1;
    },
    closeErrorDialog(value) {
      this.showError = value;
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.rejectTest();
      }
    },
    async rejectTest() {
      try {
        this.isLoading = true;
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_TEST_STATUS_MUTATION,
          variables: { id: this.test.id, status: false, reason: this.reason },
        });
        this.updateTestState(data.updateTestStatus);
        this.reject();
        this.success = "Teste rejeitado com sucesso";
        this.showSuccess = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
  // created(){
  //   if(this.test.reason){
  //     this.valid = false
  //   }
  // }
};
</script>